<template>
  <div class="emptyData">
    <img class="noOrder" src="../../assets/imgs/noneOrder.png" />
    <div class="noTXT">暂无订单</div>
  </div>
</template>

<script>
export default {
  name: "EmptyData",

  date() {
    return {
      userInfo: {},
    };
  },

  props: {},

  watch: {},

  mounted: function () {},

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.emptyData {
  width: 100%;
  height: 100%;
  padding: 0.2rem 0.3rem;
  position: absolute;
  @include background_color("title_bg");

  .noOrder {
    width: 4.5rem;
    height: 4.5rem;
    margin: 1.6rem auto 0;
    display: block;
  }
  .noTXT {
    text-align: center;
    font-size: 0.36rem;
    margin-top: 0.3rem;
    @include font_color("title_color");
  }
}
</style>
