<template>
  <div class="contain bg" ref="contains">
    <div class="myOrder title_bg title_color" ref="refHeight">我的订单</div>
    <!-- 下拉刷新 -->
    <PullDownRefresh @refresh="refresh">
      <!-- 空数据 -->
      <EmptyData v-if="emptyDataFlag" />
      <!-- 有数据 -->
      <div v-else class="orderList">
        <div class="tip bg tip_color">仅展示3个月之内的数据</div>
        <template v-for="item in orderList">
          <OrderCard
            :item="item"
            :key="item.orderCode"
            :handleCommented="handleCommented"
          />
        </template>
      </div>
    </PullDownRefresh>

    <!-- 评价 -->
    <Evaluation v-if="commentedShow" :show="commentedShow" />
  </div>
</template>

<script>
import request from "@/utils/request";
import OrderCard from "./OrderCard.vue";
import EmptyData from "./EmptyData.vue";
import Evaluation from "./Evaluation.vue";
import PullDownRefresh from "@/components/PullDownRefresh";

export default {
  name: "OrderList",
  components: {
    OrderCard,
    EmptyData,
    Evaluation,
    PullDownRefresh,
  },
  data() {
    return {
      orderList: [],
      emptyDataFlag: false,
      commentedShow: false,
      scrollTop: 0,
    };
  },

  //在页面离开时记录滚动位置
  beforeRouteLeave(to, from, next) {
    this.scrollTop = this.$refs.refHeight.getBoundingClientRect().top;
    console.log(this.scrollTop);
    next();
  },

  activated() {
    // 等到DOM更新完成之后，然后，执行this.$nextTick，类似于Promise then()
    this.$nextTick(() => {
      this.$refs.contains.scrollTo(0, this.scrollTop * -1);
    });
  },

  created: function () {
    console.log("--into created--");
  },
  mounted: function () {
    console.log("--into mounted--");
    this.getOrderList();
    // document.body.addEventListener(
    //   "touchmove",
    //   function (e) {
    //     e.preventDefault();
    //   },
    //   { passive: false }
    // );
    this.$refs.contains.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    // this.$refs.contains.removeEventListener("scroll", this.handleScroll);
    this.utils.setTitle("");
  },
  methods: {
    getOrderList(done) {
      let data = {};
      this.$loading.start();
      request({
        url: "/web/order/getOrderList",
        method: "POST",
        data: data,
        headers: {
          "Content-Type": "application/json",
        },
      }).then((res) => {
        done && done();
        let orderList = res;
        orderList.map((item) => {
          item.formatPrice = this.$splitPrice(item.totalPrice); //  实付金额
          item.formatFinalRefundPrice = this.$splitPrice(item.finalRefundPrice); //  退款金额  0=未退款,1=已申请退款,2=已退款,3=退款失败,4=用户发起退款,5=驳回退款申请,6=再次申请,7=再次被驳回
        });
        if (orderList.length > 0) {
          this.emptyDataFlag = false;
        } else {
          this.emptyDataFlag = true;
        }
        this.orderList = orderList;
        // this.orderList = [];
      });
    },

    handleCommented() {
      this.commentedShow = true;
    },

    refresh(done) {
      this.getOrderList(done);
    },
    handleScroll() {
      let scrollTop = this.$refs.refHeight.getBoundingClientRect().top;
      let oneHeight = this.$refs.refHeight.offsetHeight;
      if (-oneHeight >= scrollTop) {
        this.utils.setTitle("我的订单");
      } else {
        this.utils.setTitle("");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.bg {
  @include background_color("bg");
}
.title_bg {
  @include background_color("title_bg");
}
.title_color {
  @include font_color("title_color");
}
.tip_color {
  @include font_color("tip_color");
}

.contain {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 1.16rem;
  overflow: scroll;
  .myOrder {
    width: 100%;
    height: 1.16rem;
    line-height: 1.16rem;
    font-size: 0.48rem;
    text-indent: 0.48rem;
    margin: 0 auto;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .orderList {
    width: 100%;
    // background: #f5f5f5;
    padding-bottom: 0.2rem;
    @include background_color("bg");
    .tip {
      height: 0.93rem;
      line-height: 0.93rem;
      /*margin-bottom: .30rem;*/
      font-size: 0.24rem;
      color: #aeb5ca;
      text-align: center;

      & + .order {
        margin-top: 0;
      }
    }
  }
}
</style>
