<template>
  <div class="card card_bg" @click="toOrderDetail">
    <div class="card-header">
      <div class="hearder-vin card_header_color">
        <span>无人车 {{ item.vinId }}</span>
        <span :style="item.orderStatus === '已抵达' ? 'color: #FF6E40' : ''">{{
          item.orderStatus
        }}</span>
      </div>
      <div class="hearder-date card_date_color">{{ item.createDate }}</div>
    </div>
    <div class="card-layer1">
      <div class="imgBox" v-for="cell in item.skuList" :key="cell.skuName">
        <img :src="cell.skuImage" alt="" />
      </div>
      <div class="imgBoxGradient imgBox_bg_image"></div>
      <div class="priceBox">
        <div class="price card_price_color">
          <span>￥</span>
          <span>{{ item.formatPrice[0] }}</span>
          <span>{{ item.formatPrice[1] }}</span>
        </div>
        <div class="number">共{{ item.skuTotalCount || 0 }}件</div>
      </div>
    </div>
    <div class="refund" v-if="item.refundOrderStatus > 0">
      <span class="refund-key">退款状态</span>
      <span class="refund-name"
        >{{ item.refundOrderStatusValue }}
        <span v-if="item.refundOrderStatus === 2">￥</span>
        <span v-if="item.refundOrderStatus === 2">{{
          item.formatFinalRefundPrice[0]
        }}</span>
        <span v-if="item.refundOrderStatus === 2">{{
          item.formatFinalRefundPrice[1]
        }}</span>
      </span>
    </div>
    <div class="card-bottom">
      <!-- <span class="refund" @click="refund">退款</span> -->
      <!-- <span
        class="refundRecord"
        @click="toRefundRecord"
        v-if="
          (item.refundOrderStatus === 1 ||
            item.refundOrderStatus === 2 ||
            item.refundOrderStatus === 6 ||
            item.refundOrderStatus === 7) &&
          item.orderType !== 13
        "
        >退款记录</span
      > -->
      <a
        class="card_phone_color phone_border_color"
        @click.stop
        href="tel:400-666-7876"
        >客服</a
      >
      <span
        class="commented"
        v-if="item.commented === false && item.avatarUrl && item.nickName"
        @click.stop="commented"
        >评价
      </span>
      <!-- <span
        class="commented"
        v-if="item.commented === false && (!item.avatarUrl || !item.nickName)"
        >评价
      </span> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderCard",

  date() {
    return {};
  },

  props: {
    item: {
      type: Object,
    },
  },

  watch: {
    //正确给 cData 赋值的 方法
    orderList: function (newVal) {
      return newVal;
    },
  },

  mounted: function () {
    console.log(this.props);
  },

  methods: {
    toOrderDetail() {
      let orderType = this.item.orderType;
      let path = "";
      switch (orderType) {
        case 13:
          path = "/detailsDelivery";
          break;
        case 17:
          path = "/orderDetails";
          break;
        default:
          path = "/orderDetails";
          break;
      }
      this.$router.push({
        path: path,
        query: {
          orderCode: this.item.orderCode,
        },
      });
      this.utils.setTitle("");
    },
    toRefundRecord() {
      console.log(this.item);
      this.$route.push({
        path: "../Refund/index",
        query: {
          id: this.item.id,
        },
      });
    },
    commented() {
      this.$emit.handleCommented();
    },
    toPhone() {
      console.log(this);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.card_bg {
  @include background_color("card_bg");
}

.card_header_color {
  @include font_color("card_header_color");
}

.card_date_color {
  @include font_color("card_date_color");
}

.card_price_color {
  @include font_color("card_price_color");
}

.card_phone_color {
  @include font_color("card_phone_color");
}

.phone_border_color {
  @include border_color("phone_border_color");
}

.imgBox_bg_image {
  @include bg_image("imgBox_bg_image");
}

.card {
  width: calc(100% - 0.6rem);
  padding: 0 0.3rem;
  margin: 0 0.3rem 0.3rem;
  border-radius: 0.12rem;
  .card-header {
    width: 100%;
    padding: 0.3rem 0 0.36rem 0;
    .hearder-vin {
      width: 100%;
      height: 0.4rem;
      line-height: 0.4rem;
      margin-bottom: 0.02rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      > :first-child {
        display: inline-block;
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.28rem;
        font-weight: bold;
      }
      > :last-child {
        display: inline-block;
        height: 0.4rem;
        line-height: 0.4rem;
        font-size: 0.28rem;
      }
    }
    .hearder-date {
      height: 0.33rem;
      line-height: 0.33rem;
      font-size: 0.24rem;
    }
  }

  .card-layer1 {
    width: 100%;
    display: flex;
    position: relative;
    margin-bottom: 0.2rem;
    .imgBox {
      width: 0.96rem;
      height: 0.96rem;
      margin-right: 0.12rem;
      white-space: nowrap;
      text-align: center;
      img {
        width: 0.96rem;
        height: 0.96rem;
        display: inline-block;
        border-radius: 0.08rem;
        // margin-right: 0.24rem;
      }
    }
    .imgBoxGradient {
      width: 0.6rem;
      height: 100%;
      position: absolute;
      right: 0.96rem;
      top: 0;
      // background-image: linear-gradient(
      //   90deg,
      //   rgba(255, 255, 255, 0.5) 0%,
      //   #ffffff 100%
      // );
    }
    .priceBox {
      width: 0.96rem;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-around;
      margin-left: auto;
      .price {
        font-weight: bold;
        font-size: 0.24rem;
        > :first-child {
          font-size: 0.2rem;
        }
        > :nth-child(2) {
          font-size: 0.32rem;
          margin-right: 0.04rem;
        }
        > :last-child {
          font-size: 0.22rem;
        }
      }
      .number {
        font-size: 0.24rem;
        color: #aeb5ca;
      }
    }
  }

  .refund {
    width: 100%;
    height: 0.94rem;
    line-height: 0.94rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span.refund-key {
      font-size: 0.28rem;
      color: #aeb5ca;
    }
    span.refund-name {
      font-size: 0.28rem;
      @include font_color("card_price_color");
    }
  }

  .card-layer2 {
    border-top: 0.01rem solid #f1f1f1;
    padding: 0.24rem 0 0.08rem;
    div {
      display: flex;
      justify-content: space-between;
      font-size: 0.28rem;
      color: #999;
      span {
        font-size: 0.28rem;
        color: #222;
      }
    }
  }

  .card-bottom {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 0.3rem;
    padding-bottom: 0.48rem;
    margin-top: 0.2rem;

    span,
    a {
      padding: 0.08rem 0.36rem;
      border-width: 0.01rem;
      border-style: solid;
      border-radius: 0.3rem;
      font-size: 0.28rem;
      font-weight: 500;
      margin-left: 0.24rem;
      text-decoration: none;
      -webkit-tap-highlight-color: transparent;
      [data-theme="light"] &:active {
        background: #d9d9d9;
      }
    }

    .commented {
      background: #f6c24a;
      color: #222;
      border: none;
    }
  }
}
</style>
